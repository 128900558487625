import type { ApiResponseOk } from '@slc/types'
import compact from 'lodash/compact'
import get from 'lodash/get'
import has from 'lodash/has'
import isPlainObject from 'lodash/isPlainObject'
import merge from 'lodash/merge'
import { ApiError, FechError } from './error'

const JSON_TYPE = 'application/json'

const defaultOptions: RequestInit = {
  headers: {
    accept: JSON_TYPE,
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    'content-type': JSON_TYPE,
  },
  mode: 'cors', // no-cors, *cors, same-origin
  cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  credentials: 'include', // include, *same-origin, omit
  redirect: 'follow', // manual, *follow, error
  referrerPolicy: 'no-referrer', // no-referrer, *client
}

const INTERNAL_URLS = compact(
  [
    process.env.NEXT_PUBLIC_API_URL,
    process.env.NEXT_PUBLIC_APP_URL,
    process.env.NEXT_PUBLIC_AUTH_URL,
    process.env.NEXT_PUBLIC_CUSTOMER_URL,
    process.env.NEXT_PUBLIC_DASHBOARD_URL,
  ].map((internalUrl) => (internalUrl ? new URL(internalUrl).host : null))
)

if (!INTERNAL_URLS.length) {
  throw new Error('Missing configuration for application URLs.')
}

const getContentType = (res: Response) => {
  if (!res.headers || !res.headers.has('content-type')) {
    return null
  }

  const contentType: string | null = res.headers.get('content-type')

  return contentType ? get(contentType.split(';'), '[0]') : null
}

const getResponseType = (res: Response) => {
  const contentType = getContentType(res)
  console.log('content type', contentType)
  switch (contentType) {
    case JSON_TYPE:
      return 'json'
    case '':
      return 'binary'
    case 'text':
    default:
      return 'text'
  }
}

type ParsedResponse = {
  type: string
  status: number
  statusText: string
  body: unknown
}

const parseBody = (type, res: Response) => {
  switch (type) {
    case 'json':
      return res.json()
    case 'binary':
      return res.blob()
    case 'text':
    default:
  }
}

const parseResponse = async (res: Response): Promise<ParsedResponse> => {
  const type = getResponseType(res)
  const { status, statusText } = res

  return {
    type,
    status,
    statusText,
    body: (await parseBody(type, res)) as unknown,
  }
}

export type FetchBody = BodyInit | null | undefined

export const $fetch = async <T = unknown>(
  url: string,
  options?: RequestInit
): Promise<T> => {
  try {
    const { host } = new URL(url)
    const $options = options === undefined ? defaultOptions : options

    const payload = INTERNAL_URLS.includes(host)
      ? merge({}, defaultOptions, $options)
      : $options

    const hasContentType = has(payload, 'headers["content-type"]')
    const contentType = get(payload, 'headers["content-type"]')

    if (
      has(payload, 'body') &&
      (!hasContentType ||
        (hasContentType && contentType === 'application/json'))
    ) {
      payload.body = isPlainObject(payload.body)
        ? JSON.stringify(payload.body)
        : payload.body
    }

    const response = await fetch(url, payload)

    if (response.status === 204) {
      return { ok: true } as ApiResponseOk
    }

    const { status, body } = await parseResponse(response)

    if (!response.ok) {
      // 4xx error
      if (response.status % 400 < 99) {
        console.log('FetchError', url, response.status)
        throw new ApiError(response.statusText, {
          status,
          body: body as unknown,
        })
      }

      // 5xx error
      throw new Error(response.statusText)
    }

    return body as T
  } catch (e: unknown) {
    console.log('fetch error', e)

    throw e instanceof ApiError ? e : new FechError((e as Error).message)
  }
}

export const $get = async <T = unknown>(url: string, options?: RequestInit) =>
  $fetch<T>(url, { method: 'GET', ...(options ?? {}) })

export const $delete = async <T = unknown>(
  url: string,
  options?: RequestInit
) => $fetch<T>(url, { method: 'DELETE', ...(options ?? {}) })

export const $post = async <T = unknown>(
  url: string,
  data: FetchBody = null,
  options?: RequestInit
) =>
  $fetch<T>(url, {
    method: 'POST',
    body: data,
    ...(options ?? {}),
  })

export const $put = async <T = unknown>(
  url: string,
  data: FetchBody = null,
  options?: RequestInit
) =>
  $fetch<T>(url, {
    method: 'PUT',
    body: data,
    ...(options ?? {}),
  })

export const $patch = async <T = unknown>(
  url: string,
  data: FetchBody = null,
  options?: RequestInit
) =>
  $fetch<T>(url, {
    method: 'PATCH',
    body: data,
    ...(options ?? {}),
  })
